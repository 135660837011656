import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainPanel from "./components/lesson/MainPanel";
import Intro from './components/other/Intro';
import LessonList from './components/lesson/LessonList';
import LessonListHeader from './components/lesson/LessonListHeader';
import LessonMainHeader from './components/lesson/LessonMainHeader';
import ViewContainer from "./components/lesson/ViewContainer";

import TestPage from './components/other/TestPage';



class App extends Component {




    constructor(props){
        super(props);
        ////console.log('window.serverData:, window.serverData);

        this.state= {
	    apiUrl:'https://study.itcenglish.com/api/ts',
            //apiUrl:'https://dev.itcenglish.com/api/ts',
            //apiUrl:'api.itconline.co.kr'
            //apiUrl:'http://127.0.0.1:8080',
            //apiUrl:'http://localhost:5000/api/ts',
            userInfo:null,
            menuInfo:null,
            lessonInfo:null,
            initMenuNo:0,
            //currentPage: -1,  //?�체 STEP ?�스?�용
            currentPage: 0,  //?�체 STEP ?�스?�용
            //currentPage: 99,  //TESTING
            //currentMenu: 65,
            tempStepInfo:{
                step:null,
                std_lesson:null,
            },
            isPT:false,
            serverData: window.serverData,
            lessonType:'',
        };

        this._renderPage = this._renderPage.bind(this);
        this._handleCurrentPage = this._handleCurrentPage.bind(this);
        // this._handleCurrentMenu = this._handleCurrentMenu.bind(this);

    }

    componentDidUpdate(prevProps, prevState){
        // if(prevState.currentPage !== this.state.currentPage) { //?�이지 변�???
        //     this.setState({
        //         lessonType:'',
        //     })
        // }

    }


    _setTempStepInfo = (step, std_lesson) => {
        this.setState({
            tempStepInfo:{
                step,
                std_lesson,
            }
        })
    };


    _setUserInfo = (userInfo) => {
        this.setState({
            userInfo
        })
    };

    _setMenuInfo = (menuInfo) => {
        this.setState({
            menuInfo
        })
    };

    _setLessonInfo = (lessonInfo, initMenuNo) => {
        ////console.log("In APp.js, lessonInfo is:", lessonInfo);

        this.setState({
            lessonInfo,
            initMenuNo,
            lessonType:''
        })
    };

    _setTypingLessonInfo = (lessonInfo) => {
        this.setState({
            lessonInfo,
            lessonType: 'typing',
            initMenuNo:1,
        })
    };

    _setIsPT = (bool) => {
        this.setState({
            isPT:bool
        });
    }


  render() {
      // //console.log("current lesson Info is :", this.state.lessonInfo);
      ////console.log(React.version);
      return (

          this._renderPage(this.state.currentPage)



      );
  }



  _renderPage(page){
      switch(page){
          case -1:
              return(
                  <div>
                      <TestPage
                          // handleCurrentPage={this._handleCurrentPage}
                          // setStepInfo={this._setTempStepInfo}
                      />
                  </div>
              );
          case 0:
              return (<div>
                        <Intro
                            handleCurrentPage={this._handleCurrentPage}
                            tempStepInfo={this.state.tempStepInfo}
                            setUserInfo={this._setUserInfo.bind(this)}
                            apiUrl={this.state.apiUrl}
                            serverData={this.state.serverData}
                        />
                    </div>);
          case 1:
              return (
                  <>
                    {/*<LessonFrame currentPage = {this.state.currentPage}*/}
                                 {/*setUserInfo = {this._setUserInfo.bind(this)}*/}
                                 {/*handleCurrentPage={this._handleCurrentPage}/>*/}

                    <LessonListHeader currentPage = {this.state.currentPage}
                                userInfo = {this.state.userInfo}
                                serverData={this.state.serverData}
                                handleCurrentPage={this._handleCurrentPage} />

                    <LessonList userInfo = {this.state.userInfo}
                                apiUrl={this.state.apiUrl}
                                handleCurrentPage={this._handleCurrentPage}
                                setLessonInfo = {this._setLessonInfo.bind(this)}
                                setTypingLessonInfo = {this._setTypingLessonInfo.bind(this)}
                                serverData={this.state.serverData}
                                setIsPT = {this._setIsPT}/>
                  </>
                    );
          case 2:
              return (
                  <ViewContainer currentPage={this.state.currentPage}
                                 lessonInfo={this.state.lessonInfo}
                                 initMenuNo={this.state.initMenuNo}
                                 setUserInfo={this._setUserInfo.bind(this)}
                                 handleCurrentPage={this._handleCurrentPage}
                                 setToggleMenu={this._setToggleMenu}
                                 setMenuInfo={this._setMenuInfo.bind(this)}
                                 setLessonInfo = {this._setLessonInfo.bind(this)}
                                 setTypingLessonInfo = {this._setTypingLessonInfo.bind(this)}
                                // currentMenu={this.state.currentMenu}
                                 toggleMenu={this.state.toggleMenu}
                                 // handleCurrentMenu={this._handleCurrentMenu}
                                 userInfo={this.state.userInfo}
                                 menuInfo={this.state.menuInfo}
                                 isPT={this.state.isPT}
                                 apiUrl={this.state.apiUrl}
                                 serverData={this.state.serverData}
                                 lessonType={this.state.lessonType}
                  />
              );
          // case 2:
          //     return (
          //         <div>
          //             <LessonMainHeader currentPage = {this.state.currentPage}
          //                               lessonInfo = {this.state.lessonInfo}
          //                               setUserInfo = {this._setUserInfo.bind(this)}
          //                               handleCurrentPage={this._handleCurrentPage}
          //                               setToggleMenu = {this._setToggleMenu}
          //                               setMenuInfo = {this._setMenuInfo.bind(this)}
          //                               currentMenu = {this.state.currentMenu}
          //                               toggleMenu = {this.state.toggleMenu}
          //                               handleCurrentMenu = {this._handleCurrentMenu}
          //             />
          //             <MainPanel userInfo = {this.state.userInfo}
          //                        menuInfo = {this.state.menuInfo}
          //                        currentMenu = {this.state.currentMenu}
          //                        lessonInfo = {this.state.lessonInfo}
          //                        handleCurrentMenu = {this._handleCurrentMenu}
          //             />
          //         </div>
          //     );


          case 99: //TEST PAGE
              const tempLessonInfo = {
                  lesson_idx: 338,
                  lesson_seq: 1,
                  step_code: "L1",
                  type_code: " ",
                  lesson_no: "01",
                  lesson_title_en: "Greetings",
                  lesson_title_kr: "?�사",
                  lesson_img: "L1_01_img.png",
                  lesson_img2: "L1_01_D_01.png",
                  mobile_img: "L1_01_M_img.png",
                  lesson_vod: null,
                  lesson_pcount: "0",
                  lesson_free: "Y",
                  reg_date: "2015-11-05T09:14:00.000Z"
              };
              return (
                  <div>
                      <LessonMainHeader currentPage = {this.state.currentPage}

                                        setUserInfo = {this._setUserInfo.bind(this)}
                                        handleCurrentPage={this._handleCurrentPage}


                                        lessonInfo = {tempLessonInfo}
                                        setMenuInfo = {this._setMenuInfo.bind(this)}
                                      //  currentMenu = {this.state.currentMenu}
                                        toggleMenu = {this.state.toggleMenu}
                                        // handleCurrentMenu = {this._handleCurrentMenu}
                      />
                      <MainPanel userInfo = {this.state.userInfo}
                                 menuInfo = {this.state.menuInfo}
                                // currentMenu = {this.state.currentMenu}
                                 lessonInfo = {tempLessonInfo}
                                 // handleCurrentMenu = {this._handleCurrentMenu}
                      />
                  </div>
              );

          default:
              return(<div>page connector error.</div>);

      }
  }

  _handleCurrentPage = (value) => {
      this.setState({
          currentPage: value
      })
  };

  // _handleCurrentMenu = (value) => {
  //
  //     this.setState ({
  //         currentMenu: value,
  //     });
  //     //console.log("current menu is:" + this.state.currentMenu);
  //     //console.log("and input value is" + value);
  // };


}

export default App;
