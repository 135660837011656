import React, {Component} from 'react';
import withContents from "../../withContents";
import Footer_PR2 from '../../Footer_PR2';
import Score from '../../Score';
import {brTagActivator, calculateScore, FailScore, getCurrentMenuInfo, isIOS} from "../../../../asset/js/myFunctions";
import LoadingBar from "../../../other/LoadingBar";


class PracticeForReading2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            //illustRootURL: 'https://dev.itcenglish.com/contents/ts',
            // illustRootURL: 'https://study.itcenglish.com/contents/ts',
            // illustRootURL:'',
            index: 0,
            isStart: false,
            maxPage: 0,
            currentPage: 1,
            result:null,
            wordInfo:{
                num: 0,
                getResult: false,
            },
            recognizeComplete:false,
            pass:false,
            err:null,
            errCount:0,
            tryCount:0,
            isTesting:false,
        };

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.currentPage !== prevState.currentPage){ //페이지 바뀔 때
            nextProps.closeTip();
            return {
                currentPage: nextProps.currentPage,
                index: nextProps.currentPage - 1,
                result: null,
                err:null,
                pass:false,
                errCount:0,
                tryCount:0,
            }
        }


        if(nextProps.contents !== null && prevState.maxPage !== nextProps.contents.length){
            nextProps.setMaxPage(nextProps.contents.length);

            return {
                maxPage: nextProps.contents.length,
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState){
        if(!prevState.recognizeComplete && this.state.recognizeComplete) { //음성인식 종료되면 테스트 모드 종료
            this.setState({
                isTesting:false,
            })
        }

        if(this.state.isPlayingComplete && !this.state.recognizeComplete) {
            //음원지 종료되고 아직 음성인식이 종료가 안된 상태라면,
            this.props.endRecognition();
        }
    }


    render() {
        if(this.props.contents === null || this.props.menuInfo === null){
            return (<LoadingBar type={1}/>);
        }
        if(!this.state.isStart) {
            return(
                this._renderIntro()
            );
        }
        ////console.log("PP Contents is :", this.props.contents);
        return (
            this._renderMain()
        );

    }


    _next(){
        if(this.state.index >= this.props.contents.length - 1) {
            //console.log("다음 메뉴로 이동합니다.");
            this.props.goNextMenu();
            return;
        }
        this.setState({
            index: this.state.index + 1,
        });
    }

    _renderMain = () => {
        return (
            <>
                <section className={this.props.lessonInfo.lesson_no === "01" ? "view-body tip-opened" : "view-body"}>
                    <div className="content-wrap align-start HideScrollBar">
                        <div className="sound-box">
                            <img
                                src={`${this.props.illustRootURL}/illust/${this.props.lessonInfo.step_code.replace(/\s/g, '')}/${this.props.contents[this.state.currentPage - 1].tpk_url}`}
                                className="sound-deco-img CropImageInL4" alt="Cover"/>
                            <div className="sound-box-text">
                                {this.props.contents[this.state.index].eng_content}
                                <div className={"sound-box-divider"}>
                                    <button
                                        className="btn-play-sound"
                                        onClick={() => {
                                            if(!this.props.isPlaying){
                                                //this.props.audioPlay("eng", this.state.index)
                                                this.audioPlay(this.state.index)
                                            }
                                        }}
                                    >발음듣기
                                    </button>
                                </div>
                                {
                                    this.state.recognizeComplete && !this.props.listening  //음원 재생중 && 녹음중 에는 점수 출력 X
                                        ?
                                        <div className={"abc-score"}>
                                            <Score //scoreType={this.props.userInfo.score_type}//임시로 score_type 무조건 1.상의필요
                                                scoreType={1}
                                                result={this.state.result}
                                                original={this.props.contents[this.state.index].eng_content}/>
                                        </div>
                                        :
                                        ""
                                }
                            </div>

                        </div>
                        {/*<p className="Err">*/}
                            {/*{this.state.result.length < 10 && this.state.recognizeComplete ? this.state.err : ""}*/}
                        {/*</p>*/}

                    </div>

                    <div className="lecture-step">
                        <span className="current">{this.state.currentPage}</span>/<span className="total">{this.state.maxPage}</span>
                    </div>

                    <button type="button" className="btn-tip-toggle" onClick={() =>this.props.initTipToggle()}>팁 토글</button>
                    <div className={`tip-container tip-bg-${this.props.step123}`}>
                        <div className="message-box">

                            {
                                this.state.err === null ?
                                    <>
                                        문장을 잘 보고 "삐" 소리 후 <br/> <strong>영어로 읽으세요</strong>
                                        <hr className="divider" />
                                        <div className="tip">버튼을 눌러 소리를 들어보실 수 있습니다. <br />
                                        </div>
                                    </>
                                    :
                                    this.state.err.map((textOrHtml, index) => <span key={index}>{textOrHtml}</span>)
                            }
                        </div>
                    </div>
                </section>
                <Footer_PR2
                    {...this.props}
                    c_code={this.props.c_code}
                    setResult={this._setResult}
                    setRecognizeComplete = {this._setRecognizeComplete}
                    startTest={this._startTest}
                    isTesting={this.state.isTesting}
                    wordInfo={this.state.wordInfo}
                    index={this.state.index}
                    // audioPlayMute = {this.props.audioPlayMute}
                    setHowlerMute={this.props.setHowlerMute}
                    audioStop = {this.props.audioStop}
                    handleError={this._handleError}
                    pass={this.state.pass}
                />
            </>
        )

    };


    _renderIntro = () => {
        return (
            <section className="view-body" style={{height:this.props.bodyHeight-this.props.headerHeight}}>
                <div className="content-wrap">
                    <h2 className="intro-title">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_title_en)}</h2>
                    <p className="intro-desc">{brTagActivator(getCurrentMenuInfo(this.props.menuInfo, this.props.currentMenu).menu_guide)}</p>
                    <button type="button" className="btn-lecture-start CursorPointer" onClick={() => this._start()}>시작하기</button>
                </div>
            </section>
        )
    };

    _start = () => {
        this.setState({isStart: true})
    };

    _startTest = () => {
        this.setState({
            isTesting:true,
        })
    };

    audioPlay = (index) => {
        if(!this.props.contents) {
            console.log("not loaded contents yet");
            return;
        }
        let src = `${this.props.contents[index].step_code}/${this.props.contents[index].lesson_no}/${this.props.contents[index].eng_url}`;

        this.props.setHowler(
            src
        );
    }

    _setResult = (result) => {
        //console.log("set Result :", result);
        if(result == null){
            this.setState({
                result:null,
            });
            return;
        }
        //console.log("isPlaying? ", this.props.isPlaying);
        if(!this.props.isPlaying){ //음성인식 종료된 후만 결과값 컨트롤함.
            this.setState({
                result: result,
                err:null,

            });

            let score = isIOS ? 0 : calculateScore(result,this.props.contents[this.state.index].eng_content);
            let pass = score > FailScore || isIOS;

            this._decidePass(pass);

        }
    };

    _decidePass = (isPass) => {
        if(this.state.tryCount >= 2 && !isPass) { //2번이상 시도하면 무조건 통과
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:true,
                err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
            });
            return;
        }

        if(!isPass) { //fail 처리 후 카운트 + 1
            this.props.openTip(); //팝업 띄움
            this.setState({
                pass:this.state.pass ? true : isPass,
                tryCount:this.state.tryCount+1,
                err: ["다음 문장을 잘 보고", <br />,"다시 한번 ",<strong>테스트</strong>, "하세요.",
                    <div className="divider"/>,<div className="tip">자신감있게 더 큰 목소리로 따라해보세요.<br />버튼을 눌러 소리를 들어보실 수 있습니다.</div>],
            })
        } else {
            this.props.closeTip();
            this.setState({
                pass:isPass,
                err:null,
            })
        }
    };


    _setRecognizeComplete = (isComplete) => {
        this.setState({
            recognizeComplete: isComplete
        })
    };


    _handleError = (err) => {
        ////console.log("음성인식이 잘 되지 않았습니다. 다시 테스트하세요.");
        if(err === null){
            this.setState({
                err:err,
            });
            return;
        }
        if(!this.props.isPlaying){ //음성재생이 종료되고 나서 에러를 컨트롤 함.
            if(this.state.errCount >= 1){
                this.setState({
                    result: '##ERROR',
                    err: ["특정 문장은 인식이 잘 되지 않는 경우도 있습니다.", <br />, "발음 문제가 아니니 너무 상심하지 마세요."],
                    pass:true,
                });

                return;
            }


            this.props.openTip();

            this.setState({
                err:err,
                errCount:this.state.errCount + 1,
            })
        }

    };



}




//export default withContents(`http://localhost:8080/contents/${this.props.serverData.step_code}/01/${this.state.c_code}`)(PracticePronunciation);
export default withContents()(PracticeForReading2);

