import React, {Component} from 'react';
import withLessonList from './withLessonList';
import $ from 'jquery';
import LoadingBar from "../other/LoadingBar";
import axios from "axios";
import {isMobile} from "../../asset/js/myFunctions";
import {apiKey} from "../../secret/keys";

axios.defaults.headers.common['Authorization'] = apiKey;

class LessonList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            illustRootURL: 'https://study.itcenglish.com/contents/ts',
            //illustRootURL: 'https://dev.itcenglish.com/contents/ts',
            // illustRootURL:'',
            isHover: null,
            lastMenuNo: 0,
            isQuerying: false,
        };

        this._goLessonStart = this._goLessonStart.bind(this);
        this._renderLessonList = this._renderLessonList.bind(this);
    }




    componentDidUpdate(prevProps, prevState) {
        $('body').css('padding-top', 80);

        if (prevProps.userInfo.std_lesson !== this.props.userInfo.std_lesson) { //std_lesson이 업데이트 되었다면 lastMenuNo = 1로 변경
            if (prevProps.serverData.auth_type === "F" || prevProps.serverData.auth_type === "A") { //프리레슨and 관리자 모드는 리턴
                return;
            }
            //console.log("std lesson update");
            this.setState({
                lastMenuNo: 1,
            })

        }


        if (prevState.lastMenuNo === 0 && this.props.lessonList && !this.state.isQuerying) { //lastMenuNo가 아직 로드 되지 않았다면, 로드 시도.
            if (prevProps.serverData.auth_type === "F" || prevProps.serverData.auth_type === "A") { //프리레슨and 관리자 모드는 리턴
                return;
            }

            //console.log("메뉴 넘버 조회");
            this.setState({
                isQuerying: true,
            });
            let lesson_idx = 0;
            for (let i = 0; i < this.props.lessonList.length; i++) {
                if (this.props.lessonList[i].lesson_seq === this.props.userInfo.std_lesson) {
                    lesson_idx = this.props.lessonList[i].lesson_idx;

                    break;
                }
            }
            this._getMenuNo(this.props.apiUrl, lesson_idx, this.props.serverData.mem_idx, this.props.serverData.class_idx);
        }

    }


    componentDidMount() {
        ////console.log(this.props);
        $(function () {
            // 리스트 애니메이션
            let $listItem = $('.lesson-list > li');
            $listItem.each(function () {
                $(this).addClass('nv');
            });

            $.fn.visible = function (partial) {
                let $t = $(this),
                    $w = $(window),
                    viewTop = $w.scrollTop(),
                    viewBottom = viewTop + $w.height(),
                    _top = $t.offset().top,
                    _bottom = _top + $t.height(),
                    compareTop = partial === true ? _bottom : _top,
                    compareBottom = partial === true ? _top : _bottom;
                return ((compareBottom <= viewBottom) && (compareTop >= viewTop))
            };

            $(window).scroll(function () {
                $listItem.each(function (i, el) {
                    let $el = $(el);
                    if ($el.visible(true)) {
                        $el.delay(5000).removeClass('nv');
                    } else {
                        $el.addClass('nv');
                    }
                });
            }).scroll();

            $listItem.click(function () {
                // alert("clicked by jquery!");
            });

            // 리스트 아이템 마우스 오버시 이벤트
            $listItem.not('.locked').find('.list-item').on('mouseenter', function () {
                //alert("hello item");
                let $this = $(this);
                $this.off('mouseleave.card');
                $listItem.removeClass('hover');
                $this.parents('li').addClass('hover');

                setTimeout(function () {
                    $this.off('mouseleave.card').on('mouseleave.card', function () {
                        $(this).parents('li').removeClass('hover');
                    })
                }, 150);
            });

            // 리스트 아이템 클릭시 이벤트
            $('.list-item:not(.locked) .front', $listItem).on('click', function () {
                $(this).parent('.list-item').mouseenter();
            });
        });


    }


    _getMenuNo = (apiUrl, lesson_idx, mem_idx, class_idx) => {
        let attend_idx = 0;
        let menuNo = 0;
        Promise.all([axios({
                method: 'get',
                url: `${apiUrl}/class/${lesson_idx}/${mem_idx}/${class_idx}`.replace(/\s/g, ''), //attend_idx 조회
                data: {
                    apiKey: apiKey,
                }
            }
        )]).then(([res]) => {
            ////console.log(res);
            if (res.data.recordset[0]) { //attend_idx 조회 후, menu_no 조회함.
                ////console.log(res.data.recordset[0]);
                if (res.data.recordset[0].attend_idx !== undefined) {
                    attend_idx = res.data.recordset[0].attend_idx;
                    ////console.log(attend_idx);
                    Promise.all([axios({
                            method: 'get',
                            url: `${apiUrl}/class/menu/${attend_idx}`.replace(/\s/g, ''), //menu_no 조회
                            data: {
                                apiKey: apiKey,
                            }
                        }
                    )]).then(([res2]) => {
                        ////console.log(res2);
                        menuNo = res2.data.recordset[0].menu_no; //중단값
                        //console.log("menuNo:",menuNo);
                        //return menuNo;
                        this.setState({
                            lastMenuNo: menuNo,
                            isQuerying: false,
                        })

                    }).catch(err => {
                        //console.log("에러 발견", err);
                        //메뉴 넘버 업데이트 실패

                    }).then(() => {
                        //에러 발견시, 콜백

                    });
                } else {
                    //attend_idx가 조회되지 않아 lastMenuNo = 60로 설정.
                    this.setState({
                        lastMenuNo: 1,
                    })
                }

            } else {
                this.setState({ //attend_idx 조회에 실패시,
                    lastMenuNo: 1,
                })
            }

        }).catch(err => {
            //console.log("에러 발견", err);
            //메뉴 넘버 업데이트 실패

        }).then(() => {
            //에러 발견시, 콜백

        });

    };

    _onMouseEnter(id) {
        this.setState({
            isHover: id,
        })
        ////console.log($(this));
        //$(this).addClass('hover');
    }

    _lessonListClick(id) {
        //console.log("클릭", id);
        this.setState({
            isHover: id,
        })
        ////console.log($(this));
        //$(this).addClass('hover');
    }


    _onBackgroundClick() {
        //console.log("백그라운드 클릭");
        this.setState({
            isHover: null,
        });
    }

    _goLessonStart = (lessonInfo, group_no) => { //group_no 1:학습, 2: 빠른 학습 , 3: 추가학습 or PT
        // if(this.props.userInfo.std_lesson == null){
        //     return;
        // }
        //
        // if(this.props.userInfo.std_lesson < lessonInfo.lesson_seq) {
        //     return;
        // }

        const ptSteps = ['L9', 'L10', 'L11', 'L12'];
        if (!ptSteps.includes(lessonInfo.step_code.replace(/\s/g, '')) && group_no === 3) { //추가학습의 경우 attend_gb 일단 미결정
            lessonInfo.attend_gb = 'U';
        } else {
            lessonInfo.attend_gb = lessonInfo.lesson_seq === this.props.userInfo.std_lesson ? 'N' : 'R';
        }

        let initMenuNo;
        switch (group_no) { //학습하기 시작메뉴 : 1, 빠른학습하기 시작메뉴 : 6, 추가학습 시작메뉴 : 31
            case 1:
                initMenuNo = 1;
                break;
            case 2:
                initMenuNo = 6;
                break;
            case 3:
                initMenuNo = 31;
                break;
            default:
                initMenuNo = 1;
                break;
        }

        lessonInfo.group_no = group_no;

        this.props.setLessonInfo(lessonInfo, initMenuNo) //App.js 에 수강할 레슨 정보 저장

        this.props.handleCurrentPage(2);
        //console.log("레슨 시작 LessonInfo is: ", lessonInfo);
    };

    _goTypingStart = (lessonInfo) => {
        if (isMobile) {
            alert("모바일에선 타이핑을 수강하실 수 없습니다. PC를 이용해 주세요.");
            return;
        }

        // if(this.props.userInfo.std_lesson == null){
        //     return;
        // }
        // if(this.props.userInfo.std_lesson < lessonInfo.lesson_seq) {
        //     return;
        // }

        lessonInfo.attend_gb = 'U'; //일단 미결정
        this.props.handleCurrentPage(2);
        //console.log("타이핑 레슨 시작 LessonInfo is: ", lessonInfo);
        this.props.setTypingLessonInfo(lessonInfo) //App.js 에 수강할 레슨 정보 저장
    };

    _renderFreeLessonList = (lessons) => {
        // //console.log("lessonList info, this.props.lessonLists is: ",lessons);
        // //console.log("lessonList info, this.props.userInfo is: ",this.props.userInfo);
        //if(this.props.userInfo.std_lesson >= 231) {
        let lessonList = [];
        let freeLessonList = [];

        let ptLessons = ['L9', 'L10', 'L11', 'L12'];

        //PT있는 레슨
        if (ptLessons.includes(this.props.serverData.step_code.replace(/\s/gi, ""))) {

            for (let i = 0; i < lessons.length; i++) {
                //프리 레슨 카드 푸쉬
                if (lessons[i].lesson_free === "Y") {
                    freeLessonList.push(
                        <>
                            <li key={lessons[i].lesson_idx} id={lessons[i].lesson_idx}
                                className={this.state.isHover === lessons[i].lesson_idx ? "hover" : ""}
                                onMouseEnter={() => {
                                    this._onMouseEnter(lessons[i].lesson_idx)
                                }}

                                //레슨리스트 클릭해도 카드가 뒤집어지도록 함.
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this._lessonListClick(lessons[i].lesson_idx)
                                }}
                            >
                                <div className="list-item">
                                    <div className="front">
                                        <figure className="thumb">
                                            <img
                                                src={
                                                    isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img}`}
                                                alt="Lesson 1"
                                                className="not-opened"
                                            />
                                        </figure>
                                        <h5>Lesson {lessons[i].lesson_no}</h5>
                                        <h2>{lessons[i].lesson_title_kr}</h2>


                                    </div>
                                    <div className="back">
                                        <ul>
                                            <li key={"lesson" + lessons[i].lesson_idx}>
                                                <a onClick={() => {
                                                    this._goLessonStart(lessons[i], 1);
                                                    this.props.setIsPT(false);
                                                }}
                                                   className={"CursorPointer"}>학습하기</a></li>
                                            <li key={"fast_lesson" + lessons[i].lesson_idx}>
                                                <a onClick={() => {
                                                    this._goLessonStart(lessons[i], 2);
                                                    this.props.setIsPT(false);
                                                }}
                                                   className={"CursorPointer"}>스피드학습</a>
                                            </li>
                                            <li key={"typing" + lessons[i].lesson_idx}>
                                                <a onClick={() => {

                                                    this._goTypingStart(lessons[i]);
                                                    this.props.setIsPT(false);
                                                }}
                                                   className={"CursorPointer"}>타이핑하기</a>
                                            </li>
                                            <li key={"lesson_book" + lessons[i].lesson_idx}>
                                                <a
                                                    className={"CursorPointer"}
                                                    onClick={() => {
                                                        this._showTextbook(lessons[i].lesson_no)
                                                    }}
                                                >
                                                    교재보기
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            {/*PT 레슨카드*/}
                            <li key={lessons[i].lesson_idx + "PT"} id={lessons[i].lesson_idx + "PT"}
                                className={this.state.isHover === lessons[i].lesson_idx + "PT" ? "hover" : ""}

                                onMouseEnter={() => {
                                    this._onMouseEnter(lessons[i].lesson_idx + "PT")
                                }}

                                //카드 클릭해도 뒤집어짐
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this._lessonListClick(lessons[i].lesson_idx + "PT")
                                }}
                            >
                                <div className="list-item">
                                    <div className="front">
                                        <figure className="thumb">
                                            <img
                                                src={
                                                    isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img2}`}
                                                alt="Lesson 1"/>
                                        </figure>
                                        <h5>Lesson {lessons[i].lesson_no} PRESENTATION</h5>
                                        <h2>{lessons[i].lesson_title_kr}</h2>


                                    </div>
                                    <div className="back">
                                        <ul>
                                            <li key={"lesson_pt" + lessons[i].lesson_idx + "PT"}>
                                                <a onClick={() => {
                                                    this._goLessonStart(lessons[i], 3);
                                                    this.props.setIsPT(true);
                                                }}
                                                   className={"CursorPointer"}>
                                                    학습하기
                                                </a>
                                            </li>
                                            <li key={"lesson_book_pt" + lessons[i].lesson_idx + "PT"}>
                                                <a
                                                    className={"CursorPointer"}
                                                    onClick={() => {
                                                        this._showPTTextbook(lessons[i].lesson_no)
                                                    }}
                                                >교재보기</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </>
                    )
                    //잠금 레슨 카드 푸쉬
                } else {
                    lessonList.push(
                        <>
                            <li key={lessons[i].lesson_idx} id={lessons[i].lesson_idx}
                                className={"locked"}
                                onMouseEnter={() => {
                                    return null;
                                }}
                                onClick={(event) => {
                                    return null;
                                }}
                            >
                                <div className="list-item">
                                    <div className="front">
                                        <figure className="thumb">
                                            <img
                                                src={
                                                    isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img}`}
                                                alt="Lesson 1"
                                                className="not-opened"
                                            />
                                        </figure>
                                        <h5>Lesson {lessons[i].lesson_no}</h5>
                                        <h2>{lessons[i].lesson_title_kr}</h2>


                                    </div>
                                    <div className="back">
                                        <ul>
                                            <li key={"lesson" + lessons[i].lesson_idx}>
                                                <a onClick={() => {
                                                    this._goLessonStart(lessons[i], 1);
                                                    this.props.setIsPT(false);
                                                }}
                                                   className={"CursorPointer"}>학습하기</a></li>
                                            <li key={"fast_lesson" + lessons[i].lesson_idx}>
                                                <a onClick={() => {
                                                    this._goLessonStart(lessons[i], 2);
                                                    this.props.setIsPT(false);
                                                }}
                                                   className={"CursorPointer"}>스피드학습</a>
                                            </li>
                                            <li key={"typing" + lessons[i].lesson_idx}>
                                                <a onClick={() => {

                                                    this._goTypingStart(lessons[i]);
                                                    this.props.setIsPT(false);
                                                }}
                                                   className={"CursorPointer"}>타이핑하기</a>
                                            </li>
                                            <li key={"lesson_book" + lessons[i].lesson_idx}>
                                                <a
                                                    className={"CursorPointer"}
                                                    onClick={() => {
                                                        this._showTextbook(lessons[i].lesson_no)
                                                    }}
                                                >교재보기</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            {/*PT 레슨카드*/}
                            <li key={lessons[i].lesson_idx + "PT"} id={lessons[i].lesson_idx + "PT"}
                                className={"locked"}
                                onMouseEnter={() => {
                                    return null;
                                }}
                                onClick={(event) => {
                                    return null;
                                }}
                            >
                                <div className="list-item">
                                    <div className="front">
                                        <figure className="thumb">
                                            <img
                                                src={
                                                    isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img2}`}
                                                alt="Lesson 1"/>
                                        </figure>
                                        <h5>Lesson {lessons[i].lesson_no} PRESENTATION</h5>
                                        <h2>{lessons[i].lesson_title_kr}</h2>


                                    </div>
                                    <div className="back">
                                        <ul>
                                            <li key={"lesson_pt" + lessons[i].lesson_idx + "PT"}>
                                                <a onClick={() => {
                                                    this._goLessonStart(lessons[i], 3);
                                                    this.props.setIsPT(true);
                                                }}
                                                   className={"CursorPointer"}>
                                                    학습하기
                                                </a>
                                            </li>
                                            <li key={"lesson_book_pt" + lessons[i].lesson_idx + "PT"}>
                                                <a
                                                    className={"CursorPointer"}
                                                    onClick={() => {
                                                        this._showPTTextbook(lessons[i].lesson_no)
                                                    }}
                                                >교재보기</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </>
                    )
                }
            }
            //PT 없는 레슨
        } else {

            for (let i = 0; i < lessons.length; i++) {
                if (lessons[i].lesson_free === "Y") {
                    //프리레슨리스트 푸쉬
                    freeLessonList.push(
                        <li key={lessons[i].lesson_idx} id={lessons[i].lesson_idx}
                            className={this.state.isHover === lessons[i].lesson_idx ? "hover" : ""}
                            onMouseEnter={() => {
                                this._onMouseEnter(lessons[i].lesson_idx)
                            }}

                            //카드 클릭해도 뒤집어짐
                            onClick={(event) => {
                                event.stopPropagation();
                                this._lessonListClick(lessons[i].lesson_idx)
                            }}
                        >
                            <div className="list-item">
                                <div className="front">
                                    <figure className="thumb">
                                        <img
                                            src={
                                                isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img}`}
                                            alt="Lesson 1"/>
                                    </figure>
                                    <h5>Lesson {lessons[i].lesson_no}</h5>
                                    <h2>{lessons[i].lesson_title_kr}</h2>


                                </div>
                                <div className="back">
                                    <ul>
                                        <li key={"lesson" + lessons[i].lesson_idx}>
                                            <a onClick={() => {
                                                this._goLessonStart(lessons[i], 1)
                                            }}
                                               className={"CursorPointer"}>학습하기
                                            </a>
                                        </li>
                                        {
                                            this.props.serverData.step_code === "J1" || this.props.serverData.step_code === "J2" || this.props.serverData.step_code === "J3" ?
                                                ""
                                                :
                                                <>
                                                    <li key={"fast_lesson" + lessons[i].lesson_idx}>
                                                        <a onClick={() => {
                                                            this._goLessonStart(lessons[i], 2)
                                                        }}
                                                           className={"CursorPointer"}>스피드학습
                                                        </a>
                                                    </li>
                                                    <li key={"extra_lesson" + lessons[i].lesson_idx}>
                                                        <a onClick={() => {
                                                            this._goLessonStart(lessons[i], 3)
                                                        }}
                                                           className={"CursorPointer"}>응용학습
                                                        </a>
                                                    </li>
                                                </>

                                        }

                                        <li key={"typing" + lessons[i].lesson_idx}>
                                            <a
                                                onClick={() => {
                                                    ////console.log("타이핑연습")

                                                    this._goTypingStart(lessons[i]);
                                                }}
                                                className={"CursorPointer"}
                                            >
                                                타이핑연습
                                            </a>
                                        </li>
                                        <li key={"lesson_book" + lessons[i].lesson_idx}>
                                            <a
                                                onClick={() => {
                                                    this._showTextbook(lessons[i].lesson_no)
                                                }}
                                                className={"CursorPointer"}
                                            >
                                                교재보기
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    )
                    //잠금 레슨리스트 푸쉬
                } else {
                    lessonList.push(
                        <li key={lessons[i].lesson_idx} id={lessons[i].lesson_idx}
                            className={"locked"}
                            onMouseEnter={() => {
                                return null;
                            }}
                            //카드 클릭해도 뒤집어짐
                            onClick={(event) => {
                                return null;
                            }}
                        >
                            <div className="list-item">
                                <div className="front">
                                    <figure className="thumb">
                                        <img
                                            src={
                                                isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img}`}
                                            alt="Lesson 1"/>
                                    </figure>
                                    <h5>Lesson {lessons[i].lesson_no}</h5>
                                    <h2>{lessons[i].lesson_title_kr}</h2>


                                </div>
                                <div className="back">
                                    <ul>
                                        <li key={"lesson" + lessons[i].lesson_idx}>
                                            <a onClick={() => {
                                                this._goLessonStart(lessons[i], 1)
                                            }}
                                               className={"CursorPointer"}>학습하기
                                            </a>
                                        </li>
                                        {
                                            this.props.serverData.step_code === "J1" || this.props.serverData.step_code === "J2" || this.props.serverData.step_code === "J3" ?
                                                ""
                                                :
                                                <>
                                                    <li key={"fast_lesson" + lessons[i].lesson_idx}>
                                                        <a onClick={() => {
                                                            this._goLessonStart(lessons[i], 2)
                                                        }}
                                                           className={"CursorPointer"}>스피드학습
                                                        </a>
                                                    </li>
                                                    <li key={"extra_lesson" + lessons[i].lesson_idx}>
                                                        <a onClick={() => {
                                                            this._goLessonStart(lessons[i], 3)
                                                        }}
                                                           className={"CursorPointer"}>응용학습
                                                        </a>
                                                    </li>
                                                </>

                                        }

                                        <li key={"typing" + lessons[i].lesson_idx}>
                                            <a
                                                onClick={() => {
                                                    ////console.log("타이핑연습")

                                                    this._goTypingStart(lessons[i]);
                                                }}
                                                className={"CursorPointer"}
                                            >
                                                타이핑연습
                                            </a>
                                        </li>
                                        <li key={"lesson_book" + lessons[i].lesson_idx}>
                                            <a
                                                onClick={() => {
                                                    this._showTextbook(lessons[i].lesson_no)
                                                }}
                                                className={"CursorPointer"}
                                            >
                                                교재보기
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    )
                }
            }
        }

        return (
            <>
                {freeLessonList}
                {lessonList}
            </>
        )
    };

    _renderAdminLessonList = (lessons) => {
        // //console.log("lessonList info, this.props.lessonLists is: ",lessons);
        // //console.log("lessonList info, this.props.userInfo is: ",this.props.userInfo);
        //if(this.props.userInfo.std_lesson >= 231) {
        let lessonList = [];

        let ptLessons = ['L9', 'L10', 'L11', 'L12'];

        //PT있는 레슨
        if (ptLessons.includes(this.props.serverData.step_code.replace(/\s/gi, ""))) {

            for (let i = 0; i < lessons.length; i++) {
                //프리 레슨 카드 푸쉬

                lessonList.push(
                    <>
                        <li key={lessons[i].lesson_idx} id={lessons[i].lesson_idx}
                            className={this.state.isHover === lessons[i].lesson_idx ? "hover" : ""}
                            onMouseEnter={() => {
                                this._onMouseEnter(lessons[i].lesson_idx)
                            }}

                            //레슨리스트 클릭해도 카드가 뒤집어지도록 함.
                            onClick={(event) => {
                                event.stopPropagation();
                                this._lessonListClick(lessons[i].lesson_idx)
                            }}
                        >
                            <div className="list-item">
                                <div className="front">
                                    <figure className="thumb">
                                        <img
                                            src={
                                                isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img}`}
                                            alt="Lesson 1"
                                            className="not-opened"
                                        />
                                    </figure>
                                    <h5>Lesson {lessons[i].lesson_no}</h5>
                                    <h2>{lessons[i].lesson_title_kr}</h2>


                                </div>
                                <div className="back">
                                    <ul>
                                        <li key={"lesson" + lessons[i].lesson_idx}>
                                            <a onClick={() => {
                                                this._goLessonStart(lessons[i], 1);
                                                this.props.setIsPT(false);
                                            }}
                                               className={"CursorPointer"}>학습하기</a></li>
                                        <li key={"fast_lesson" + lessons[i].lesson_idx}>
                                            <a onClick={() => {
                                                this._goLessonStart(lessons[i], 2);
                                                this.props.setIsPT(false);
                                            }}
                                               className={"CursorPointer"}>스피드학습</a>
                                        </li>
                                        <li key={"typing" + lessons[i].lesson_idx}>
                                            <a onClick={() => {
                                                this._goTypingStart(lessons[i]);
                                                this.props.setIsPT(false);
                                            }}
                                               className={"CursorPointer"}>타이핑하기</a>
                                        </li>
                                        <li key={"lesson_book" + lessons[i].lesson_idx}>
                                            <a
                                                className={"CursorPointer"}
                                                onClick={() => {
                                                    this._showTextbook(lessons[i].lesson_no)
                                                }}
                                            >교재보기</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        {/*PT 레슨카드*/}
                        <li key={lessons[i].lesson_idx + "PT"} id={lessons[i].lesson_idx + "PT"}
                            className={this.state.isHover === lessons[i].lesson_idx + "PT" ? "hover" : ""}

                            onMouseEnter={() => {
                                this._onMouseEnter(lessons[i].lesson_idx + "PT")
                            }}

                            //카드 클릭해도 뒤집어짐
                            onClick={(event) => {
                                event.stopPropagation();
                                this._lessonListClick(lessons[i].lesson_idx + "PT")
                            }}
                        >
                            <div className="list-item">
                                <div className="front">
                                    <figure className="thumb">
                                        <img
                                            src={
                                                isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img2}`}
                                            alt="Lesson 1"/>
                                    </figure>
                                    <h5>Lesson {lessons[i].lesson_no} PRESENTATION</h5>
                                    <h2>{lessons[i].lesson_title_kr}</h2>


                                </div>
                                <div className="back">
                                    <ul>
                                        <li key={"lesson_pt" + lessons[i].lesson_idx + "PT"}>
                                            <a onClick={() => {
                                                this._goLessonStart(lessons[i], 3);
                                                this.props.setIsPT(true);
                                            }}
                                               className={"CursorPointer"}>
                                                학습하기
                                            </a>
                                        </li>
                                        <li key={"lesson_book_pt" + lessons[i].lesson_idx + "PT"}>
                                            <a
                                                onClick={() => {
                                                    this._showPTTextbook(lessons[i].lesson_no)
                                                }}
                                                className={"CursorPointer"}
                                            >교재보기</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </>
                )
                //잠금 레슨 카드 푸쉬

            }
            //PT 없는 레슨
        } else {

            for (let i = 0; i < lessons.length; i++) {
                lessonList.push(
                    <li key={lessons[i].lesson_idx} id={lessons[i].lesson_idx}
                        className={this.state.isHover === lessons[i].lesson_idx ? "hover" : ""}
                        onMouseEnter={() => {
                            this._onMouseEnter(lessons[i].lesson_idx)
                        }}

                        //카드 클릭해도 뒤집어짐
                        onClick={(event) => {
                            event.stopPropagation();
                            this._lessonListClick(lessons[i].lesson_idx)
                        }}
                    >
                        <div className="list-item">
                            <div className="front">
                                <figure className="thumb">
                                    <img
                                        src={
                                            isMobile ? `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].mobile_img}` : `${this.state.illustRootURL}/illust/${lessons[i].step_code.replace(/\s/g, '')}/${lessons[i].lesson_img}`}
                                        alt="Lesson 1"/>
                                </figure>
                                <h5>Lesson {lessons[i].lesson_no}</h5>
                                <h2>{lessons[i].lesson_title_kr}</h2>


                            </div>
                            <div className="back">
                                <ul>
                                    <li key={"lesson" + lessons[i].lesson_idx}>
                                        <a onClick={() => {
                                            this._goLessonStart(lessons[i], 1)
                                        }}
                                           className={"CursorPointer"}>학습하기
                                        </a>
                                    </li>
                                    {
                                        this.props.serverData.step_code === "J1" || this.props.serverData.step_code === "J2" || this.props.serverData.step_code === "J3" ?
                                            ""
                                            :
                                            <>
                                                <li key={"fast_lesson" + lessons[i].lesson_idx}>
                                                    <a onClick={() => {
                                                        this._goLessonStart(lessons[i], 2)
                                                    }}
                                                       className={"CursorPointer"}>스피드학습
                                                    </a>
                                                </li>
                                                <li key={"extra_lesson" + lessons[i].lesson_idx}>
                                                    <a onClick={() => {
                                                        this._goLessonStart(lessons[i], 3)
                                                    }}
                                                       className={"CursorPointer"}>응용학습
                                                    </a>
                                                </li>
                                            </>

                                    }


                                    <li key={"typing" + lessons[i].lesson_idx}>
                                        <a
                                            onClick={() => {
                                                ////console.log("타이핑연습")
                                                this._goTypingStart(lessons[i]);
                                            }}
                                            className={"CursorPointer"}
                                        >
                                            타이핑연습
                                        </a>
                                    </li>
                                    <li key={"lesson_book" + lessons[i].lesson_idx}>
                                        <a
                                            onClick={() => {
                                                this._showTextbook(lessons[i].lesson_no)
                                            }}
                                            className={"CursorPointer"}
                                        >
                                            교재보기
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                )

            }
        }

        return (
            <>
                {lessonList}
            </>
        )
    };

    _renderLessonList = (lessons) => {
        // //console.log("lessonList info, this.props.lessonLists is: ",lessons);
        // //console.log("lessonList info, this.props.userInfo is: ",this.props.userInfo);
        //if(this.props.userInfo.std_lesson >= 231) {
        if (this.props.serverData.step_code === "L9" || this.props.serverData.step_code === "L10" || this.props.serverData.step_code === "L11" || this.props.serverData.step_code === "L12") {
            ////console.log("PT가 있는 학생임");
            return (
                lessons.map(lesson => {
                    return (
                        <>
                            <li key={lesson.lesson_idx} id={lesson.lesson_idx}
                                className={lesson.lesson_seq > this.props.userInfo.std_lesson //std_lesson < lesson_seq 인 경우는 "locked"
                                    ? "locked"
                                    : lesson.lesson_seq === this.props.userInfo.std_lesson  //std_lesson === lesson_seq 인 경우는 "current"
                                        ? this.state.lastMenuNo > 30 ? //30보다 큰 경우는 "not current"

                                            (this.state.isHover === lesson.lesson_idx ? "hover" : "")
                                            :
                                            (this.state.isHover === lesson.lesson_idx ? "hover current" : "current")
                                        :
                                        (this.state.isHover === lesson.lesson_idx ? "hover" : "")
                                }
                                onMouseEnter={lesson.lesson_seq <= this.props.userInfo.std_lesson ? () => {
                                    this._onMouseEnter(lesson.lesson_idx)
                                } : () => {
                                    return null;
                                }}

                                //레슨리스트 클릭해도 카드가 뒤집어지도록 함.
                                onClick={(event) => {
                                    //console.log("클릭");
                                    if (lesson.lesson_seq > this.props.userInfo.std_lesson) {
                                        return null;
                                    }
                                    event.stopPropagation();
                                    this._lessonListClick(lesson.lesson_idx)
                                }}
                            >
                                <div className="list-item">
                                    <div className="front">
                                        <figure className="thumb">
                                            <img
                                                src={
                                                    isMobile ? `${this.state.illustRootURL}/illust/${lesson.step_code.replace(/\s/g, '')}/${lesson.mobile_img}` : `${this.state.illustRootURL}/illust/${lesson.step_code.replace(/\s/g, '')}/${lesson.lesson_img}`}
                                                alt="Lesson 1"
                                                className="not-opened"
                                            />
                                        </figure>
                                        <h5>Lesson {lesson.lesson_no}</h5>
                                        <h2>{lesson.lesson_title_kr}</h2>


                                    </div>
                                    <div className="back">
                                        <ul>
                                            <li key={"lesson" + lesson.lesson_idx}>
                                                <a onClick={() => {
                                                    if(this.props.userInfo.std_lesson == null){
                                                        return;
                                                    }
                                                    if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                        return;
                                                    }

                                                    this._goLessonStart(lesson, 1);
                                                    this.props.setIsPT(false);
                                                }}
                                                   className={"CursorPointer"}>학습하기</a></li>
                                            {/*{*/}
                                                {/*this.props.userInfo.class_type === "O" || this.props.userInfo.class_type === "P" ?*/}
                                                    {/*""*/}
                                                    {/*:*/}
                                                    {/*this.props.userInfo.student_gb === "N" ?*/}
                                                        {/*<li key={"fast_lesson" + lesson.lesson_idx}>*/}
                                                            {/*<a onClick={() => {*/}
                                                                {/*this._goLessonStart(lesson, 2);*/}
                                                                {/*this.props.setIsPT(false);*/}
                                                            {/*}}*/}
                                                               {/*className={"CursorPointer"}>스피드학습</a>*/}
                                                        {/*</li>*/}
                                                        {/*:*/}
                                                        {/*""*/}

                                            {/*}*/}
                                            {
                                                this.props.userInfo.student_gb === "S" ?
                                                    "" //"S"로 막으면 스피드학습 이용 불가
                                                    :
                                                    this.props.userInfo.class_type === "O" ?
                                                        "" //베이직 수강권 스피드학습 이용 불가
                                                        :
                                                        this.props.userInfo.class_type === "P" ?
                                                            lesson.lesson_seq >= this.props.userInfo.std_lesson ?
                                                                 //프리미엄 수강권 수강완료 안한경우 스피드 학습 이용 불가
                                                                <li key={"fast_lesson" + lesson.lesson_idx}>
                                                                    <a className={"disabled"}>스피드학습</a>
                                                                </li>
                                                                :
                                                                <li key={"fast_lesson" + lesson.lesson_idx}>
                                                                    <a onClick={() => {
                                                                        if(this.props.userInfo.std_lesson == null){
                                                                            return;
                                                                        }
                                                                        if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                                            return;
                                                                        }
                                                                        this._goLessonStart(lesson, 2);
                                                                        this.props.setIsPT(false);
                                                                    }}
                                                                       className={"CursorPointer"}>스피드학습</a>
                                                                </li>
                                                            :
                                                            <li key={"fast_lesson" + lesson.lesson_idx}>
                                                                <a onClick={() => {
                                                                    if(this.props.userInfo.std_lesson == null){
                                                                        return;
                                                                    }
                                                                    if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                                        return;
                                                                    }
                                                                    this._goLessonStart(lesson, 2);
                                                                    this.props.setIsPT(false);
                                                                }}
                                                                   className={"CursorPointer"}>스피드학습</a>
                                                            </li>
                                            }
                                            <li key={"typing" + lesson.lesson_idx}>
                                                <a
                                                    className={
                                                        lesson.lesson_seq < this.props.userInfo.std_lesson ?
                                                            "CursorPointer"
                                                            :

                                                            this.state.lastMenuNo < 30
                                                                ?
                                                                "disabled"
                                                                :
                                                                "CursorPointer"
                                                    }
                                                    onClick={
                                                        lesson.lesson_seq < this.props.userInfo.std_lesson ?
                                                            () => {
                                                                if(this.props.userInfo.std_lesson == null){
                                                                    return;
                                                                }
                                                                if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                                    return;
                                                                }
                                                                this._goTypingStart(lesson);
                                                                this.props.setIsPT(false);
                                                            }
                                                            :
                                                            this.state.lastMenuNo < 30 ?

                                                                () => {
                                                                    return;
                                                                }
                                                                :
                                                                () => {
                                                                    if(this.props.userInfo.std_lesson == null){
                                                                        return;
                                                                    }
                                                                    if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                                        return;
                                                                    }
                                                                    this._goTypingStart(lesson);
                                                                    this.props.setIsPT(false);
                                                                }
                                                    }
                                                >타이핑하기</a>
                                            </li>
                                            <li key={"lesson_book" + lesson.lesson_idx}>
                                                <a
                                                    className={lesson.lesson_seq < this.props.userInfo.std_lesson ?
                                                        "CursorPointer"
                                                        :

                                                        this.state.lastMenuNo < 30
                                                            ?
                                                            "disabled"
                                                            :
                                                            "CursorPointer"
                                                    }
                                                    onClick={
                                                        lesson.lesson_seq < this.props.userInfo.std_lesson ?
                                                            () => {
                                                                this._showTextbook(lesson.lesson_no);
                                                            }
                                                            :
                                                            this.state.lastMenuNo < 30 ?
                                                                () => {
                                                                    return;
                                                                }
                                                                :
                                                                () => {
                                                                    this._showTextbook(lesson.lesson_no);
                                                                }
                                                    }
                                                >교재보기</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>

                            {/*PT 레슨카드*/}
                            <li key={lesson.lesson_idx + "PT"} id={lesson.lesson_idx + "PT"}
                                className={lesson.lesson_seq > this.props.userInfo.std_lesson ? //std_lesson < lesson_seq 인 경우는 "locked"
                                    "locked"
                                    : lesson.lesson_seq === this.props.userInfo.std_lesson ? //std_lesson === lesson_seq 인 경우는 "menu_no 조회"
                                        this.state.lastMenuNo < 30 ? //30보다 작은 경우는 "locked"

                                            "locked"
                                            :
                                            (this.state.isHover === lesson.lesson_idx + "PT" ? "hover current" : "current")
                                        :
                                        (this.state.isHover === lesson.lesson_idx + "PT" ? "hover" : "")

                                }


                                onMouseEnter={lesson.lesson_seq <= this.props.userInfo.std_lesson ? () => {
                                    this._onMouseEnter(lesson.lesson_idx + "PT")
                                } : () => {
                                    return null;
                                }}

                                //카드 클릭해도 뒤집어짐
                                onClick={(event) => {
                                    //console.log("클릭");
                                    if (lesson.lesson_seq > this.props.userInfo.std_lesson) {
                                        return null;
                                    }
                                    event.stopPropagation();
                                    this._lessonListClick(lesson.lesson_idx + "PT")
                                }}
                            >
                                <div className="list-item">
                                    <div className="front">
                                        <figure className="thumb">
                                            <img
                                                src={
                                                    isMobile ? `${this.state.illustRootURL}/illust/${lesson.step_code.replace(/\s/g, '')}/${lesson.mobile_img}` : `${this.state.illustRootURL}/illust/${lesson.step_code.replace(/\s/g, '')}/${lesson.lesson_img2}`}
                                                alt="Lesson 1"/>
                                        </figure>
                                        <h5>Lesson {lesson.lesson_no} PRESENTATION</h5>
                                        <h2>{lesson.lesson_title_kr}</h2>


                                    </div>
                                    <div className="back">
                                        <ul>
                                            <li key={"lesson_pt" + lesson.lesson_idx + "PT"}>
                                                <a onClick={() => {
                                                    if(this.props.userInfo.std_lesson == null){
                                                        return;
                                                    }
                                                    if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                        return;
                                                    }
                                                    this._goLessonStart(lesson, 3);
                                                    this.props.setIsPT(true);
                                                }}
                                                   className={"CursorPointer"}>
                                                    학습하기
                                                </a>
                                            </li>
                                            <li key={"lesson_book_pt" + lesson.lesson_idx + "PT"}>
                                                <a
                                                    className={lesson.lesson_seq === this.props.userInfo.std_lesson && this.state.lastMenuNo >= 30 ? "disabled" : "CursorPointer"}
                                                    onClick={lesson.lesson_seq === this.props.userInfo.std_lesson && this.state.lastMenuNo >= 30 ? "" : () => {
                                                        this._showPTTextbook(lesson.lesson_no);
                                                    }}

                                                >교재보기</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </>
                    )
                })

            )
        }

        //PT가 없는 학생
        return (
            lessons.map(lesson => {
                return (
                    <li key={lesson.lesson_idx} id={lesson.lesson_idx}
                        className={lesson.lesson_seq > this.props.userInfo.std_lesson //std_lesson < lesson_seq 인 경우는 "locked"
                            ? "locked"
                            : lesson.lesson_seq === this.props.userInfo.std_lesson  //std_lesson === lesson_seq 인 경우는 "current"
                                ? (this.state.isHover === lesson.lesson_idx ? "hover current" : "current")
                                : (this.state.isHover === lesson.lesson_idx ? "hover" : "")}
                        onMouseEnter={lesson.lesson_seq <= this.props.userInfo.std_lesson ? () => {
                            this._onMouseEnter(lesson.lesson_idx)
                        } : () => {
                            return null;
                        }}

                        //카드 클릭해도 뒤집어짐
                        onClick={(event) => {
                            //console.log("클릭");
                            if (lesson.lesson_seq > this.props.userInfo.std_lesson) {
                                return null;
                            }
                            event.stopPropagation();
                            this._lessonListClick(lesson.lesson_idx)
                        }}
                    >
                        <div className="list-item">
                            <div className="front">
                                <figure className="thumb">
                                    <img
                                        src={
                                            isMobile ? `${this.state.illustRootURL}/illust/${lesson.step_code.replace(/\s/g, '')}/${lesson.mobile_img}` : `${this.state.illustRootURL}/illust/${lesson.step_code.replace(/\s/g, '')}/${lesson.lesson_img}`}
                                        alt="Lesson 1"/>
                                </figure>
                                <h5>Lesson {lesson.lesson_no}</h5>
                                <h2>{lesson.lesson_title_kr}</h2>


                            </div>
                            <div className="back">
                                <ul>
                                    <li key={"lesson" + lesson.lesson_idx}>
                                        <a onClick={() => {
                                            if(this.props.userInfo.std_lesson == null){
                                                return;
                                            }
                                            if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                return;
                                            }
                                            this._goLessonStart(lesson, 1)
                                        }}
                                           className={"CursorPointer"}>학습하기
                                        </a>
                                    </li>
                                    {
                                        this.props.serverData.step_code === "J1" || this.props.serverData.step_code === "J2" || this.props.serverData.step_code === "J3" ?
                                            ""
                                            :
                                            <>
                                                {
                                                    this.props.userInfo.student_gb === "S" ?
                                                        "" //"S"로 막으면 스피드학습 이용 불가
                                                        :
                                                        this.props.userInfo.class_type === "O" ?
                                                            "" //베이직 수강권 스피드학습 이용 불가
                                                            :
                                                            this.props.userInfo.class_type === "P" ?
                                                                lesson.lesson_seq >= this.props.userInfo.std_lesson ?
                                                                    //프리미엄 수강권 수강완료 안한경우 스피드 학습 이용 불가
                                                                    <li key={"fast_lesson" + lesson.lesson_idx}>
                                                                        <a className={"disabled"}>스피드학습</a>
                                                                    </li>
                                                                    :
                                                                    <li key={"fast_lesson" + lesson.lesson_idx}>
                                                                        <a onClick={() => {
                                                                            if(this.props.userInfo.std_lesson == null){
                                                                                return;
                                                                            }
                                                                            if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                                                return;
                                                                            }
                                                                            this._goLessonStart(lesson, 2);
                                                                            this.props.setIsPT(false);
                                                                        }}
                                                                           className={"CursorPointer"}>스피드학습</a>
                                                                    </li>
                                                                :
                                                                <li key={"fast_lesson" + lesson.lesson_idx}>
                                                                    <a onClick={() => {
                                                                        if(this.props.userInfo.std_lesson == null){
                                                                            return;
                                                                        }
                                                                        if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                                            return;
                                                                        }
                                                                        this._goLessonStart(lesson, 2);
                                                                        this.props.setIsPT(false);
                                                                    }}
                                                                       className={"CursorPointer"}>스피드학습</a>
                                                                </li>
                                                }
                                                {
                                                    this.props.userInfo.class_gb === "X" ?
                                                        ""
                                                        :
                                                        <li key={"extra_lesson" + lesson.lesson_idx}>
                                                            <a onClick={
                                                                lesson.lesson_seq === this.props.userInfo.std_lesson ? "" : () => {
                                                                    if(this.props.userInfo.std_lesson == null){
                                                                        return;
                                                                    }
                                                                    if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                                        return;
                                                                    }
                                                                    this._goLessonStart(lesson, 3);
                                                                }}
                                                               className={lesson.lesson_seq === this.props.userInfo.std_lesson ? "disabled" : "CursorPointer"}>응용학습
                                                            </a>
                                                        </li>
                                                }

                                            </>

                                    }

                                    <li key={"typing" + lesson.lesson_idx}>
                                        <a
                                            onClick={lesson.lesson_seq === this.props.userInfo.std_lesson ? "" : () => {
                                                if(this.props.userInfo.std_lesson == null){
                                                    return;
                                                }
                                                if(this.props.userInfo.std_lesson < lesson.lesson_seq) {
                                                    return;
                                                }
                                                this._goTypingStart(lesson);
                                            }}
                                            className={lesson.lesson_seq === this.props.userInfo.std_lesson ? "disabled" : "CursorPointer"}
                                        >
                                            타이핑연습
                                        </a>
                                    </li>
                                    <li key={"lesson_book" + lesson.lesson_idx}>
                                        <a
                                            className={lesson.lesson_seq === this.props.userInfo.std_lesson ? "disabled" : "CursorPointer"}
                                            onClick={lesson.lesson_seq === this.props.userInfo.std_lesson ? "" : () => {

                                                this._showTextbook(lesson.lesson_no);
                                            }}
                                        >교재보기</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                )
            })

        )
    };


    render() {
        if (!this.props.lessonList) {
            ////console.log("LessonList userInfo is :", this.props.userInfo);
            //return(<div> Loading Lesson List...</div>);
            //console.log("Lesson list is not loaded yet!");
            return (<LoadingBar/>);
        }

        if (!this.props.userInfo) {
            //console.log("user Info is not loaded yet!");
            return (<LoadingBar/>);
        }

        if (this.props.serverData.auth_type === "F") {
            return (
                <article className="list-section" onClick={() => {
                    this._onBackgroundClick()
                }}>
                    <ul className="lesson-list">
                        {this._renderFreeLessonList(this.props.lessonList)}
                    </ul>
                </article>
            )
        }


        if (this.props.serverData.auth_type === "A") {
            return (
                <article className="list-section" onClick={() => {
                    this._onBackgroundClick()
                }}>
                    <ul className="lesson-list">
                        {this._renderAdminLessonList(this.props.lessonList)}
                    </ul>
                </article>
            )
        }
        ////console.log("LessonList props is :", this.props);
        return (

            <article className="list-section" onClick={() => {
                this._onBackgroundClick()
            }}>
                <ul className="lesson-list">
                    {this._renderLessonList(this.props.lessonList)}
                </ul>
            </article>

        )
    }

    _showTextbook = (lessonNum) => {
        //let url = `https://lecture.itcenglish.com/speaking/textbook/ts_textbook.asp?step_code=${this.props.serverData.step_code}&lesson_no=${lessonNum}`;
        let url = `https://www.itcenglish.com/textbook/ts/?step_code=${this.props.serverData.step_code}&lesson_no=${lessonNum}`;
        let features = `width=803, height=${window.innerHeight}`;
        window.open(url, "_blank", features);
    }

    _showPTTextbook = (lessonNum) => {
        //let url = `https://lecture.itcenglish.com/speaking/textbook/ts_textbook_pt.asp?step_code=${this.props.serverData.step_code}&lesson_no=${lessonNum}`;
        let url = `https://itcenglish.com/textbook/ts/al/?step_code=${this.props.serverData.step_code}0&lesson_no=${lessonNum}`;
        let features = `width=803, height=${window.innerHeight}`;
        window.open(url, "_blank", features);
    }

}

export default withLessonList()(LessonList);