import React, {Component} from 'react';
// import withRecognizerForMemorizeB from "./withRecognizerForMemorizeB";
import withRecognizerDialogue from "./withRecognizerDialogue";
import MicVisualizer from "../other/MicVisualizer";

/**
 * MemorizeA 에서  테스트 도중 나오는 패널.
 */

class Footer_MDA extends Component {

    constructor(props) {
        super(props);
        this.state={
            index:999,
            recording: false,
            audioURL: null,
            startPracticePronunciation: {
                index: null,
                res: null,
            },
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.letStartRecognition && this.props.letStartRecognition) {
            this.props.startRecognition();
            console.log("녹음 시작. this.props.playingIndex: ", this.props.playingIndex);
            if(this.props.serverData.user_kind === "K"){
                setTimeout(() => {
                    this.props.endRecognition();
                }, this.props.contents[this.props.playingIndex].eng_content.length * 200)

            } else if(this.props.serverData.step_code === "L3"){
                setTimeout(() => {
                    this.props.endRecognition();
                }, 1 + this.props.contents[this.props.playingIndex].eng_content.length * 145)
            }
        }
    }

    //현재 페이지를 state.index에 저장함.
    // static getDerivedStateFromProps(nextProps, prevState) {
    //
    //     if(nextProps.startPracticePronunciation !== prevState.startPracticePronunciation){
    //         if(nextProps.startPracticePronunciation.res === null && nextProps.startPracticePronunciation.index !== null && prevState.startPracticePronunciation.res === null){ //발음연습 시작 신호를 보내줬는데 아직 시작 안했으면 발음 연습을 시작함.
    //
    //             //console.log("발음연습 시작 신호가 들어와서 녹음 시작!");
    //             ////console.log("nextProps.startPracticePronunciation.res :",nextProps.startPracticePronunciation.res);
    //             ////console.log("nextProps.startPracticePronunciation.index :",nextProps.startPracticePronunciation.index);
    //             nextProps.startRecognition();
    //             if(nextProps.serverData.user_kind === "K"){
    //                 // console.log(this.props.contents[this.props.testingIndex].eng_content.length * 80);
    //                 if(nextProps.testingIndex == null){
    //                     // console.log(this.props.contents[this.props.startPracticePronunciation.index].eng_content.length * 80);
    //                     setTimeout(() => {
    //                         //console.log("timeout");
    //                         nextProps.endRecognition();
    //                     }, nextProps.contents[nextProps.startPracticePronunciation.index].eng_content.length * 200)
    //                 } else {
    //                     setTimeout(() => {
    //                         nextProps.endRecognition();
    //                     }, nextProps.contents[nextProps.testingIndex].eng_content.length * 200)
    //                 }
    //
    //             }
    //
    //             return{
    //                 startPracticePronunciation:{
    //                     index:nextProps.startPracticePronunciation.index,
    //                     res:"1",
    //                 }
    //             }
    //         }
    //
    //         //만약 시작했거나 끝났다면 state에 그대로 저장함.
    //         return{
    //             startPracticePronunciation:nextProps.startPracticePronunciation,
    //         }
    //     }
    //
    //     return null;
    //
    // }

    render() {
        if(this.props.showContinue) {
            return (
                <section className="view-footer">
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this.props.resetAudioContext();
                        }}> 테스트
                    </button>
                </section>
            )
        }

        if(!this.props.isPlaying && !this.props.listening && this.props.waiting) {
            //waiting
            //console.log("타입 B");
            return (
                <section className="view-footer">
                    <button type="button" className="f-btn">Empty</button>
                </section>
            )
        }

        ////console.log(this.props.startPracticePronunciation);
        if(!this.props.isPlaying && !this.props.listening && this.props.isTesting && this.props.err) {

            return (
                <section className="view-footer">
                    <button
                        type="button"
                        className="f-btn f-center btn-test"
                        onClick={() => {
                            this.props.startRecognition();
                            if(this.props.serverData.user_kind === "K"){
                                setTimeout(() => {
                                    //console.log("timeout");
                                    this.props.endRecognition();
                                }, this.props.contents[this.props.currentIndex].eng_content.length * 200)

                            } else if(this.props.serverData.step_code === "L3"){
                                setTimeout(() => {
                                    //console.log("timeout");
                                    this.props.endRecognition();
                                }, 1 + this.props.contents[this.props.currentIndex].eng_content.length * 110)
                            }
                            this.props.handleError(null);
                            this.props.closeTip();
                        }}> 테스트
                    </button>
                </section>
            )
        }

        return (
            <section className="view-footer">
                <button type="button" className="f-btn">Empty</button>
                {this.props.isPlaying ?
                    <button
                        type="button"
                        className="f-btn f-center btn-listen"
                    > 듣는중</button>
                    :
                    this.props.listening ?
                        <MicVisualizer/>
                        :
                        <div className="f-center-grouping">
                            <button
                                type="button"
                                className="f-btn f-center-multiple btn-listen-all"
                                onClick={() => this._readyTest()}>전체 듣기
                            </button>
                            <button
                                type="button"
                                className="f-btn f-center-multiple btn-test"
                                onClick={() => {
                                    this.props.memorizeATest(0);
                                    // this.props.resetResult();
                                }}> 테스트
                            </button>
                        </div>


                }

                {this.props.numOfPair <= this.props.currentPhase.index + 1?

                    this.props.currentPhase.pass && !this.props.isPlaying && !this.props.listening ?
                        <button
                            type="button"
                            className="f-btn btn-next-page"
                            onClick={() => {
                                this.props.goNextMenu()
                                //this._memorizeBFinalTest();
                            }}>다음 메뉴</button>
                        :
                        <button type="button" className="f-btn">Empty</button>
                    :
                    this.props.currentPhase.pass && !this.props.isPlaying && !this.props.listening ?
                        <button
                            type="button"
                            className="f-btn btn-next"
                            onClick={() => {this.props.nextPhase()}}>다음페이즈</button>
                        :
                        <button type="button" className="f-btn">Empty</button>

                }

            </section>

        );

    }

    _readyTest = () => {
        //this.props.playTill();
        this.props.audioPlayRange(0, 0, (this.props.currentPhase.index * 2) + 1 )
        if(this.props.isReadyTest === false){
            this.props.toggleReadyTest();
        }
    }

    // _memorizeTest = () => { //첫 버튼 클릭시 0번째 대화를 선택한 후 (Fake) 바로 음성인식을 시작함.
    //     this.props.closeTip();
    //     this.props.startRecognition();
    //     if(this.props.serverData.user_kind === "K"){
    //         // console.log(this.props.contents[this.props.testingIndex].eng_content.length * 80);
    //         setTimeout(() => {
    //             //console.log("timeout");
    //             this.props.endRecognition();
    //         }, this.props.contents[this.props.currentIndex].eng_content.length * 200)
    //
    //     }
    //     this.props.memorizeATest();
    //
    // }

}

export default withRecognizerDialogue()(Footer_MDA);
    
    